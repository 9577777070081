<template>
  <div class="home">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="../../static/img/banner_02.jpg" alt="">
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../static/img/banner_02.jpg" alt="">
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../static/img/banner_02.jpg" alt="">
      </van-swipe-item>
    </van-swipe>
    <div class="operate">
      <router-link class="operate_item" :to="{name:'RecruitmentList'}">
        <img class="icon" src="../../static/img/operate_03.jpg" alt="">
        <p class="txt">面试</p>
      </router-link>
      <router-link class="operate_item" :to="{name:'Entry'}">
        <img class="icon" src="../../static/img/operate_05.jpg" alt="">
        <p class="txt">入职</p>
      </router-link>
<!--      <router-link class="operate_item" :to="{name:'Home'}">-->
<!--        <img class="icon" src="../../static/img/operate_07.jpg" alt="">-->
<!--        <p class="txt">资料</p>-->
<!--      </router-link>-->
      <div class="operate_item" @click="judge()">
        <img class="icon" src="../../static/img/operate_09.jpg" alt="">
        <p class="txt">面试官</p>
      </div>
    </div>
    <div class="linear"></div>
    <div class="content">
      <div class="title">
        <h3>公司介绍</h3>
        <router-link :to="{name:'Introduce'}" class="lookmore">查看更多 ></router-link>
      </div>
      <div class="con">
        <p class="text">公司位于临沂市兰山经济开发区，多年来始终坚持技术创新、管理创新、模式创新、服务创新、产品创新、组织创新六大创新，并已连续六年被评为国内最大的安全产品供应商。
          公司主要从事安全防护用品、工业/商用清洁用品、安全仪器仪表、消防装备、安防产品等品类销售，通过整合全球品牌，形成了8大类，185个子类，共计10万余 SKU
          的工业安全用品产品线。依托云计算、大数据、物联网、区块链等新一代信息技术 借助公司自主研发的NEBULA星云系统
          、六大平台和新明辉商城，为全国12万+渠道用户和全国5000余家终端企业用户提供产品解决方案、系统对接、售后服务、供应链统筹与规划.
          仓储管理、物流管理、信息技术服务、金融支持以及商务管理等方面的供应链技术服务，并在该领域经山东省质量评价协会认定为山东省优质品牌服务。新明辉将持续加强各地分仓和办事处团队建设，在成都、北京、上海、乌鲁木齐、青岛分别设立分公司和办事处，为客户提供更加高效、便捷的服务。</p>
      </div>
    </div>
    <router-link :to="{name:'Search'}" tag="p" class="look_progress">查看进度</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import {GetApprovalList} from "@/api/examiner";

export default {
  name: 'Home',
  created() {
    this.$store.dispatch('changeLocation', '')
  },
  methods: {
    async judge() {
      await GetApprovalList({openId:this.$store.state.info.openId}).then(res=>{
        if(res.errcode === 401){
          this.$router.push({
            name: 'Login'
          })
          this.show = false;
          return
        }
        if(res.errcode == 200){
          this.$router.push({
            name: 'ApprovalList',
            query: {
              data: res.rows
            }
          })
          this.show = false
        }
      })
    },
    judgeEntry(){
      if(this.$store.state.info.re_empstatus !=1 || this.$store.state.info.re_empstatus !=4){
        this.$toast('暂时禁止进入')
        return;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .my-swipe {
    width: 100%;
    height: 265px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .operate {
    display: flex;
    align-items: center;
    padding: 20px 0;

    .operate_item {
      flex: 1;

      .icon {
        display: block;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        margin: 0 auto;
      }

      .txt {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: normal;
        color: #343434;
        margin-top: 12px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  .linear {
    width: 100%;
    height: 10px;
    background: #F7F7F7;
  }

  .content {
    box-sizing: border-box;
    padding: 0 15px 85px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 25px;

      h3 {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 700;
        color: #333333;
        margin: 0;
        line-height: 18px;
      }

      .lookmore {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #666666;
      }
    }

    .con {
      padding-top: 18px;

      .text {
        text-align: justify;
        text-indent: 2em;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        color: #333333;
      }
    }
  }

  .look_progress {
    font-family: Microsoft YaHei;
    width: 100%;
    height: 52px;
    background-color: #02DBCA;
    font-size: 18px;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    line-height: 52px;
    position: fixed;
    bottom: 0;
    cursor: pointer;
    margin: 0;
  }
}
</style>
