import axios from 'axios'
import qs from 'qs'

// create an axios instance
const service = axios.create({
    baseURL: 'http://xy.xinminghui.com', // url = base url + request url
    // baseURL: '', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    // timeout: 5000, // request timeout
    // responseType: 'json',
    // headers: {
    //     'Content-Type': 'application/json'
    // },
    transformRequest: [function (data) {
        if(data instanceof FormData){
            return data
        }
        return qs.stringify(data)
    }],
})

// request interceptor
service.interceptors.request.use(
    // config => {
    //   return config
    // },
    // error => {
    //   return Promise.reject(error)
    // }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        return res
    },
    error => {
        $vm.$toast('出现错误,请重试');
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service
