import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'
import  'lib-flexible';
import './assets/iconfont.css'
import './util/permission'  //路由跳转控制
import 'vant/lib/icon/local.css'
import image from './util/image'

Vue.config.productionTip = false
Vue.prototype.$image = image

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

window.$vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
